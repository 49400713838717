import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
    BooleanSwitchWithLabel,
    Button,
    Checkbox,
    DateTimeField,
    Label,
    LoadingButton,
    MultiSelect,
    MultiSelectItem,
    Paper,
    RadioGroup,
    RadioGroupItem,
    Select,
    SelectItem,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    TextField,
    Typography
} from "@firecms/ui";
import {
    Company,
    CompanyTokenBatch,
    fetchCompanies,
    fetchCompanyTokenBatches,
    fetchExtensionSubscriptions,
    fetchSubscriptions,
    fetchUsers,
    Subscription,
    User
} from "./api";
import { useAuthController, useSnackbarController } from "@firecms/core";
import { downloadBlob, downloadSimpleBlob } from "./InvoicesExport";
import JSZip from "jszip";

const fields_140a = [
    "ReferenzID", // User ID
    "Satzart", // KOP | POS
    "IVK_IKEmpfaenger",
    "IVK_IKSender",
    "IVK_VerarbeitungsKZ",
    "IBL_IKLeistungserbringer",
    "Hauptabrechnungszeitraum",
    "INF_VertragsKZ",
    "INF_ArtAnspruch",
    "INV_GebDatum",
    "INV_Nummer",
    "INV_IKKrankenKasse",
    "INV_Name",
    "INV_Vorname",
    "INV_GueltigkeitKarte",
    "INV_VersichertenArt",
    "INV_Personengruppe",
    "INV_DMPKennzeichen",
    "INV_Geschlecht",
    "INV_Freigabe",
    "INV_FreigabeBeginn",
    "INV_FreigabeEnde",
    "RGI_EinzelBisDatum",
    "RGI_EinzelVonDatum",
    "RGI_EinzelIKRechnungsteller",
    "RGI_EinzelIKZahlungsempfaenger",
    "RGI_EinzelInfoRechnung",
    "RGI_EinzelRechNummer",
    "ABR_Anzahl",
    "ABR_Begruendung",
    "ABR_Sachkosten",
    "ABR_Sachkostenbezeichnung",
    "ABR_DatumLeistung",
    "ABR_GebNr",
    "ABR_WertGebNr",
    "GebIDIntern",
    "DIA_ICDSchluessel"
];

const fieldsStandard = [
    "id",
    "registration_date",
    "billing_interaction",
    "subscription_start_date",
    "subscription_end_date",
    "subscription_creation_date",
    "total_interactions",
    "email",
    "birthdate",
    "insurance_number",
    "full_name",
    "company",
    "token",
    "token_alias",
    "company_token_batch_name",
    "company_token_batch_id",
    "subscription_reason"
];

type SubscriptionWithUser = Subscription & User & {
    company_name: string,
    contract_number_140a: string,
    insurance_id_140a: string
};

function mergeInvoicesAndUsersData(invoices: Subscription[], users: User[], selectedCompany: Company) {
    return invoices.map((invoice) => {
        const user = users.find((u) => String(u.id) === String(invoice.id));
        return {
            ...invoice,
            ...user,
            company_name: selectedCompany.name,
            insurance_id_140a: selectedCompany?.insurance_id_140a,
            contract_number_140a: selectedCompany?.contract_number_140a
        } as SubscriptionWithUser;
    });
}

export function ExtensionInvoicesExport() {
    const snackbar = useSnackbarController();
    const [subscriptions, setSubscriptions] = useState<SubscriptionWithUser[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [onlyBilled, setOnlyBilled] = useState<boolean>(false);
    const [onlyNonBilled, setOnlyNonBilled] = useState<boolean>(false);
    const [selectedSubscriptionReasons, setSelectedSubscriptionReasons] = useState<string[] | undefined>(undefined);
    const [selectedCompanies, setSelectedCompanies] = useState<{
        company?: Company,
        companyTokenBatches?: CompanyTokenBatch[]
    }[]>([{
        company: undefined,
        companyTokenBatches: []
    }]);

    const [splitFilesPerCompany, setSplitFilesPerCompany] = useState<boolean>(true);

    const [firebaseToken, setFirebaseToken] = useState<string | undefined>(undefined);
    const [format, setFormat] = useState<"140A" | "standard">("standard");
    const [includeExcelStringFormat, setIncludeExcelStringFormat] = useState<boolean>(true);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState<number[]>([]);
    const [reportGenerating, setReportGenerating] = useState<boolean>(false);
    const [reportGeneratingPercentage, setReportGeneratingPercentage] = useState<number>(0);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const [startDate, setStartDate] = useState<Date | null>(oneMonthAgo);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const authController = useAuthController();

    const chunkArray = <T,>(array: T[], chunkSize: number): T[][] => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const loadInvoices = async () => {
        if (!firebaseToken || !startDate || !endDate) return;
        setLoading(true);
        setReportGenerating(true);
        setError(null);

        const results: SubscriptionWithUser[] = [];
        const numCompanies = selectedCompanies.length;
        let currentCompany = 0;


        const companyChunks = chunkArray(selectedCompanies, 1);
        for (const chunk of companyChunks) {
            const chunkResults = await Promise.all(chunk.map(async ({ company, companyTokenBatches }) => {
                if (!company || !companyTokenBatches) return [];
                return await loadInvoicesForCompany(company, companyTokenBatches);
            }));
            currentCompany += chunk.length;
            setReportGeneratingPercentage((currentCompany / numCompanies) * 100);            
            results.push(...chunkResults.flat());
        }
        setSubscriptions(results);
        setLoading(false);
        setReportGeneratingPercentage(0);
        setReportGenerating(false);
    };

    const loadInvoicesForCompany = async (company: Company, companyTokenBatches: CompanyTokenBatch[]): Promise<SubscriptionWithUser[]> => {
        if (!firebaseToken || !company || !startDate || !endDate) return [];
        let allSubscriptions: Subscription[] = [];

        const tokenBatchIds = companyTokenBatches.map(tb => tb.id);
        const tokenChunkSize = 5;
        const tokenChunks = chunkArray(tokenBatchIds, tokenChunkSize);
        for (const chunk of tokenChunks) {
            const subsChunk = await fetchExtensionSubscriptions(
                firebaseToken,
                company.id,
                startDate,
                endDate,
                onlyBilled,
                onlyNonBilled,
                chunk,
                selectedSubscriptionReasons
            );
            allSubscriptions = allSubscriptions.concat(subsChunk);
        }
        const users = await fetchUsers(firebaseToken, allSubscriptions.map((i) => parseInt(i.id)));
        const subscriptionsWithUsers = mergeInvoicesAndUsersData(allSubscriptions, users, company);
        console.log({ subscriptions: allSubscriptions, users });
        subscriptionsWithUsers.sort((a, b) => parseInt(a.subscription_id) - parseInt(b.subscription_id));
        return subscriptionsWithUsers;
    };

    const doDownload = async () => {
        if (!startDate || !endDate) return;

        const startDateString = formatDate(startDate);
        const endDateString = formatDate(endDate);
        const zip = new JSZip();

        if (splitFilesPerCompany) {
            selectedCompanies.forEach(({ company }) => {
                const subs = subscriptions.filter(inv =>
                    selectedSubscriptions.includes(Number(inv.subscription_id)) &&
                    inv.company_id === company?.id
                );

                const fileName = `${company?.name ?? "invoices"}_${startDateString}_${format}_${endDateString}`;

                if (includeExcelStringFormat) {
                    let arrayData;
                    if (format === "140A") {
                        arrayData = [fields_140a, ...subs.flatMap(create140AEntriesFrom)];
                    } else if (format === "standard") {
                        arrayData = [fieldsStandard, ...subs.flatMap(createStandardEntriesFrom)];
                    } else {
                        throw Error("Unknown format");
                    }

                    const sheet = XLSX.utils.aoa_to_sheet(arrayData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
                    const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

                    zip.file(`${fileName}.xlsx`, buffer);
                } else {
                    const csv = getCSV(subs, format);
                    zip.file(`${fileName}.csv`, csv.join(""));
                }
            });

            // ✅ generate ZIP and download
            const content = await zip.generateAsync({ type: "blob" });
            downloadBlob(content, `invoices_${startDateString}_${endDateString}.zip`, "application/zip");

        } else {
            const selectedSubscriptionsForCompany = subscriptions.filter(invoiceWithUser => selectedSubscriptions.includes(parseInt(invoiceWithUser.subscription_id)));
            const uniqueCompanies = [...new Set(selectedSubscriptionsForCompany.map((invoice) => invoice.company_name))];
            const fileName = uniqueCompanies.length === 1 ? uniqueCompanies[0] : "invoices";
            if (includeExcelStringFormat) {
                let arrayData;
                if (format === "140A") {
                    arrayData = [fields_140a, ...selectedSubscriptionsForCompany.flatMap(invoiceWithUser => create140AEntriesFrom(invoiceWithUser))];
                } else if (format === "standard") {
                    arrayData = [fieldsStandard, ...selectedSubscriptionsForCompany.flatMap(invoiceWithUser => createStandardEntriesFrom(invoiceWithUser))];
                } else {
                    throw Error("Unknown format");
                }
                const sheet = XLSX.utils.aoa_to_sheet(arrayData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                downloadBlob(excelBuffer, fileName + "_" + startDateString + "_" + endDateString + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            } else {
                const csv = getCSV(selectedSubscriptionsForCompany, format);
                downloadSimpleBlob(csv, fileName + "_" + startDateString + "_" + endDateString + ".csv", "text/csv;charset=utf-8");
            }
        }
    };

    const doUpload = () => {
        // Upload logic
    };

    useEffect(() => {
        authController.getAuthToken().then((authToken) => setFirebaseToken(authToken));
    }, [authController.user]);

    if (!firebaseToken) return <div>Loading...</div>;

    const canDoRequest = selectedCompanies.length > 0 && selectedCompanies.every(({
        company,
        companyTokenBatches
    }) => company && (companyTokenBatches ?? []).length > 0);

    return (
        <div className="flex flex-row h-full w-full">
            <div className="flex flex-col justify-center items-center w-[400px] p-2">
                {error && <div>{error?.message}</div>}
                <div className="flex flex-col overflow-auto h-full w-full gap-4 my-3">
                    <Typography variant={"h3"}>Sub.Extension - Invoices</Typography>
                    {selectedCompanies.map((entry, index) => <CompanyWidgetEntry
                        company={entry.company}
                        tokenBatches={entry.companyTokenBatches}
                        firebaseToken={firebaseToken}
                        setCompany={(company) => {
                            const newSelectedCompanies = [...selectedCompanies];
                            newSelectedCompanies[index].company = company;
                            setSelectedCompanies(newSelectedCompanies);
                        }}
                        setCompanyTokenBatches={(tokenBatches) => {
                            const newSelectedCompanies = [...selectedCompanies];
                            newSelectedCompanies[index].companyTokenBatches = tokenBatches;
                            setSelectedCompanies(newSelectedCompanies);
                        }}
                        includeRemove={selectedCompanies.length > 1}
                        onRemove={() => {
                            if (selectedCompanies.length > 1) {
                                const newSelectedCompanies = [...selectedCompanies];
                                newSelectedCompanies.splice(index, 1);
                                setSelectedCompanies(newSelectedCompanies);
                            }
                        }}
                        key={`company-widget-${index}`}
                    />)}
                    <Button
                        variant={"outlined"}
                        size={"small"}
                        onClick={() => {
                            setSelectedCompanies([...selectedCompanies, {
                                company: undefined,
                                companyTokenBatches: []
                            }]);
                        }}>Add company</Button>
                    <BooleanSwitchWithLabel
                        size={"small"}
                        label={"Only billed"}
                        value={onlyBilled}
                        onValueChange={(checked) => {
                            if (checked) setOnlyNonBilled(false);
                            setOnlyBilled(checked);
                        }} />
                    <BooleanSwitchWithLabel
                        size={"small"}
                        label={"Only non-billed"}
                        value={onlyNonBilled}
                        onValueChange={(checked) => {
                            if (checked) setOnlyBilled(false);
                            setOnlyNonBilled(checked);
                        }} />
                    <SubscriptionReasonSelect
                        selectedSubscriptionReasons={selectedSubscriptionReasons}
                        setSubscriptionReasons={(subscriptionReason) => {
                            setSelectedSubscriptionReasons(subscriptionReason);
                        }}
                    />
                    <div className="flex flex-row gap-2">
                        <DateTimeField
                            size={"medium"}
                            label="Start Date"
                            value={startDate}
                            onChange={setStartDate}
                        />
                        <DateTimeField
                            size={"medium"}
                            label="End date"
                            value={endDate}
                            onChange={setEndDate}
                        />
                    </div>

                    <div className="flex items-center gap-2">
                        {reportGenerating && (
                            <div className="flex items-center gap-2 w-full">
                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                    <div
                                        className="bg-blue-600 h-2.5 rounded-full"
                                        style={{ width: `${reportGeneratingPercentage}%` }}
                                    ></div>
                                </div>
                                <span className="text-sm text-gray-600 dark:text-gray-300">
                                    {reportGeneratingPercentage}%
                                </span>
                            </div>
                        )}
                    </div>

                    <LoadingButton
                        disabled={!canDoRequest || !startDate || !endDate}
                        size={"large"}
                        loading={loading}
                        onClick={loadInvoices}
                    >
                        Update data
                    </LoadingButton>
                </div>
            </div>
            <div className="flex-grow h-full p-2 w-full overflow-auto">
                <SubscriptionsTable
                    data={subscriptions}
                    doUpload={doUpload}
                    doDownload={doDownload}
                    uploadEnabled={selectedCompanies.length === 1}
                    format={format}
                    setFormat={setFormat}
                    includeExcelStringFormat={includeExcelStringFormat}
                    setIncludeExcelStringFormat={setIncludeExcelStringFormat}
                    selectedSubscriptions={selectedSubscriptions}
                    setSelectedSubscriptions={setSelectedSubscriptions}
                    splitFilesPerCompany={splitFilesPerCompany}
                    setSplitFilesPerCompany={setSplitFilesPerCompany}
                />
            </div>
        </div>
    );
}

function SubscriptionsTable({
    data,
    doDownload,
    doUpload,
    uploadEnabled,
    format,
    setFormat,
    includeExcelStringFormat,
    setIncludeExcelStringFormat,
    selectedSubscriptions,
    setSelectedSubscriptions,
    setSplitFilesPerCompany,
    splitFilesPerCompany
}: {
    data: SubscriptionWithUser[],
    doDownload: () => void,
    doUpload: () => void,
    uploadEnabled?: boolean,
    format: "140A" | "standard",
    setFormat: (format: "140A" | "standard") => void,
    includeExcelStringFormat: boolean,
    setIncludeExcelStringFormat: (includeExcelStringFormat: boolean) => void,
    selectedSubscriptions: number[],
    setSelectedSubscriptions: (selectedSubscriptions: number[]) => void,
    splitFilesPerCompany?: boolean,
    setSplitFilesPerCompany?: (splitFilesPerCompany: boolean) => void
}) {

    useEffect(() => {
        setSelectedSubscriptions(data.map(s => parseInt(s.subscription_id)));
    }, [data]);

    const rowCount = data.length;
    const numSelected = selectedSubscriptions.length;
    const onSelectAllClick = (checked: boolean) => {
        if (checked) {
            const newSelected = data.map((n) => parseInt(n.subscription_id));
            setSelectedSubscriptions(newSelected);
            return;
        }
        setSelectedSubscriptions([]);
    };

    const isSelected = (id: number) => selectedSubscriptions.indexOf(id) !== -1;

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selectedSubscriptions.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(...selectedSubscriptions, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedSubscriptions.slice(1));
        } else if (selectedIndex === selectedSubscriptions.length - 1) {
            newSelected = newSelected.concat(selectedSubscriptions.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedSubscriptions.slice(0, selectedIndex),
                selectedSubscriptions.slice(selectedIndex + 1)
            );
        }

        setSelectedSubscriptions(newSelected);
    };

    return (
        <div className="flex flex-col h-full w-full">
            <Paper className="flex-grow w-full h-full overflow-hidden">
                <div className="max-h-full overflow-auto">
                    <Table
                        className="min-w-full "
                        aria-labelledby="tableTitle"
                    >
                        <TableHeader>
                            <TableCell>
                                <Checkbox
                                    color="primary"
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onCheckedChange={onSelectAllClick}
                                />
                            </TableCell>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? "right" : "left"}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableHeader>
                        <TableBody className="overflow-auto">
                            {data.map((row, index) => {
                                const subsId = parseInt(row.subscription_id);
                                const isItemSelected = isSelected(subsId);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        onClick={(event) => handleClick(event, subsId)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.subscription_id}
                                        className={isItemSelected ? "bg-gray-50 dark:bg-gray-800" : ""}
                                    >
                                        <TableCell>
                                            <Checkbox color="primary" checked={isItemSelected} />
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.subscription_id}
                                        </TableCell>
                                        <TableCell align="right">{row.email}</TableCell>
                                        <TableCell align="right">{row.full_name}</TableCell>
                                        <TableCell align="right">{row.insurance_number}</TableCell>
                                        <TableCell align="right">{row.company_id}</TableCell>
                                        <TableCell align="right">{row.subscription_reason}</TableCell>
                                        <TableCell align="left">{row.billed_at}</TableCell>
                                        <TableCell align="left">{row.bill_paid_at}</TableCell>
                                        <TableCell align="right">{row.token}</TableCell>
                                        <TableCell align="right">{row.token_alias}</TableCell>
                                        <TableCell align="right">{row.company_token_batch_name}</TableCell>
                                        <TableCell align="right">{row.company_token_batch_id}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
            <div className="flex flex-row gap-2 p-2 items-center justify-between max-w-full overflow-auto">
                <div>{selectedSubscriptions.length} subscriptions</div>
                <div>
                    <Typography variant={"label"}>Data Format</Typography>
                    <RadioGroup className="flex flex-col items-start gap-2"
                        defaultValue="standard"
                        id="format-group"
                        onValueChange={(value) => setFormat(value as any)}>
                        <Label
                            className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                            htmlFor="standard">
                            <RadioGroupItem id="standard" value="standard" />
                            Standard
                        </Label>
                        <Label
                            className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                            htmlFor="140A">
                            <RadioGroupItem id="140A" value="140A" />
                            140A
                        </Label>
                    </RadioGroup>
                </div>
                <div>
                    <Typography variant={"label"}>Export Format</Typography>
                    <RadioGroup className="flex flex-col items-start gap-2"
                        defaultValue="csv"
                        id="file-format-group"
                        value={includeExcelStringFormat ? "excel" : "csv"}
                        onValueChange={(value) => setIncludeExcelStringFormat(value === "excel")}>
                        <Label
                            className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                            htmlFor="excel"
                        >
                            <RadioGroupItem id="excel" value="excel" />
                            Excel
                        </Label>
                        <Label
                            className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                            htmlFor="csv"
                        >
                            <RadioGroupItem id="csv" value="csv" />
                            CSV
                        </Label>
                    </RadioGroup>
                </div>
                <BooleanSwitchWithLabel value={splitFilesPerCompany ?? false}
                    className={"w-fit"}
                    onValueChange={setSplitFilesPerCompany}
                    label={"Split files per company"} />

                <div className="flex flex-col gap-1">
                    <Button disabled={selectedSubscriptions.length === 0} onClick={doDownload}>
                        {"Download locally"}
                    </Button>
                    <Button
                        variant={"text"}
                        disabled={!uploadEnabled || selectedSubscriptions.length === 0 || format !== "standard"}
                        onClick={doUpload}
                    >
                        {"Upload to backend and mark as billed"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function CompaniesTokenBatchSelect({
    selectedCompany,
    selectedTokenBatches,
    setSelectedTokenBatches,
    authToken
}: {
    selectedCompany: Company | undefined,
    selectedTokenBatches: CompanyTokenBatch[],
    setSelectedTokenBatches: (tokenBatch: CompanyTokenBatch[]) => void,
    authToken?: string
}) {

    const [companyTokenBatches, setCompanyTokenBatches] = useState<CompanyTokenBatch[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!selectedCompany || !authToken) {
            setCompanyTokenBatches([]);
            return;
        }
        setLoading(true);
        setError(null);
        fetchCompanyTokenBatches(authToken, selectedCompany.id)
            .then((companyTokenBatches) => {
                setCompanyTokenBatches(companyTokenBatches);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [selectedCompany]);

    if (error) {
        return <div>{error?.message}</div>;
    }

    if (!companyTokenBatches) return null;


    return (
        <>
            <MultiSelect
                disabled={loading || companyTokenBatches.length === 0}
                label="Company batch"
                className="w-full"
                size="medium"
                name="company_token_batch"
                value={(selectedTokenBatches ?? []).map(tb => tb.id)}
                onValueChange={(value) => {
                    const tokenBatches = value.map((id) => companyTokenBatches.find((company) => company.id === id)).filter(Boolean) as CompanyTokenBatch[];
                    setSelectedTokenBatches(tokenBatches);
                }}
            >
                {companyTokenBatches && companyTokenBatches.map((c) => (
                    <MultiSelectItem
                        key={`select-${c.id}`}
                        value={c.id}
                    >
                        {c.name}
                    </MultiSelectItem>
                ))}
            </MultiSelect>
            {/*<Button size={"small"} variant={"outlined"} disabled={loading || !selectedCompany} onClick={() => {*/}
            {/*    setSelectedTokenBatches(companyTokenBatches ?? []);*/}
            {/*}}>Select all batches</Button>*/}
        </>
    );
}

function CompanyWidgetEntry({
    firebaseToken,
    company,
    tokenBatches,
    setCompany,
    setCompanyTokenBatches,
    includeRemove,
    onRemove
}: {
    firebaseToken?: string,
    company?: Company,
    tokenBatches?: CompanyTokenBatch[],
    setCompany: (company: Company) => void,
    setCompanyTokenBatches: (tokenBatches: CompanyTokenBatch[]) => void,
    includeRemove?: boolean,
    onRemove?: () => void
}) {
    return (
        <Paper className="p-2 flex flex-col gap-2">
            <CompaniesSelect authToken={firebaseToken} selectedCompany={company} setSelectedCompany={setCompany} />
            <CompaniesTokenBatchSelect
                authToken={firebaseToken}
                selectedCompany={company}
                selectedTokenBatches={tokenBatches ?? []}
                setSelectedTokenBatches={setCompanyTokenBatches}
            />
            {includeRemove && <Button variant={"text"} onClick={onRemove}>Remove</Button>}
        </Paper>
    );
}

function SubscriptionReasonSelect({
    selectedSubscriptionReasons,
    setSubscriptionReasons
}: {
    selectedSubscriptionReasons: string[] | undefined,
    setSubscriptionReasons: (subscriptionReason: string[] | undefined) => void
}) {

    const subscriptionReason: Record<string, string> = {
        "manual renewal": "Manual renewal",
        "manual renewal testimonial": "Manual renewal (testimonial)",
        "manual renewal friends": "Manual renewal (friends)",
        "manual renewal business": "Manual renewal (business)",
        "renewal": "Renewal from App/API",
        "other": "Other"
    }


    return (
        <>
            <div className="text-sm">Subscription Extension Reason</div>
            <MultiSelect
                name="subscription_extensions"
                className="width-full"
                value={selectedSubscriptionReasons ?? []}
                onValueChange={(value) => {
                    setSubscriptionReasons(value);
                }}
            >
                {Object.entries(subscriptionReason).map(([key, value]) => (
                    <MultiSelectItem key={key} value={key}>
                        {value}
                    </MultiSelectItem>
                ))}
            </MultiSelect>
        </>
    );
}

let companiesCache: Company[] | undefined = undefined;

function CompaniesSelect({
    selectedCompany,
    setSelectedCompany,
    authToken
}: {
    selectedCompany: Company | undefined,
    setSelectedCompany: (company: Company) => void,
    authToken?: string
}) {

    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!authToken) return;
        if (companiesCache) {
            setCompanies(companiesCache);
            return;
        }
        setLoading(true);
        setError(null);
        fetchCompanies(authToken)
            .then((companies) => {
                // sort companies by name
                companies.sort((a, b) => a.name.localeCompare(b.name));
                setCompanies(companies);
                setLoading(false);
                companiesCache = companies;
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [authToken]);

    if (error) {
        return <div>{error?.message}</div>;
    }

    return (
        <Select
            className={"w-full"}
            label="Company"
            name="company"
            size={"medium"}
            fullWidth={true}
            disabled={loading || !companies}
            value={selectedCompany?.id ?? ""}
            renderValue={(value) => {
                const company = companies.find((company) => company.id === value);
                return company?.name ?? "";
            }}
            onChange={(event) => {
                const selectedCompany = companies.find((company) => company.id === event.target.value);
                setSelectedCompany(selectedCompany!);
            }}
        >
            {companies && companies.map((c) => (
                <SelectItem
                    key={`select-${c.id}`}
                    value={c.id}
                >
                    {c.name}
                </SelectItem>
            ))}
        </Select>
    );
}

function entryToCSVRow(entry: any[]) {
    return entry
        .map((v: string | undefined) => {
            if (v === null || v === undefined) return "";
            const isString = typeof v === "string";
            let parsed: string = String(v);
            return "\"" + parsed.replaceAll("\"", "\"\"") + "\"";
        })
        .join(";") + "\r\n";
}

function differenceInYears(date1: Date, date2: Date) {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    return Math.floor(diff / (1000 * 3600 * 24 * 365));
}

function formatDate(date?: Date) {
    if (date) return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
    else return "";
}

function create140AEntriesFrom(invoiceWithUser: SubscriptionWithUser) {
    const birthDate = invoiceWithUser.birthdate ? new Date(invoiceWithUser.birthdate) : undefined;
    const age = birthDate ? differenceInYears(new Date(), birthDate) : -1;
    const nameParts = invoiceWithUser.full_name?.split(" ") ?? [];
    const name = nameParts.length > 1 ? nameParts[0] : "";
    const surname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
    return [
        [
            invoiceWithUser.id, // "ReferenzID", // User ID
            "KOP", // "Satzart", // KOP | POS
            "104027544", // "IVK_IKEmpfaenger",
            "660511111", // "IVK_IKSender",
            "10", // "IVK_VerarbeitungsKZ",
            "330922595", // "IBL_IKLeistungserbringer",
            "", // "Hauptabrechnungszeitraum"
            invoiceWithUser.contract_number_140a, // "INF_VertragsKZ",
            "0", // "INF_ArtAnspruch",
            formatDate(birthDate).replaceAll("-", ""), // "INV_GebDatum",
            invoiceWithUser.insurance_number, // "INV_Nummer",
            invoiceWithUser.insurance_id_140a, // "INV_IKKrankenKasse",
            surname, // "INV_Name",
            name, // "INV_Vorname",
            "", // "INV_GueltigkeitKarte",
            age <= 67 ? 1 : 5, // "INV_VersichertenArt",
            "0", // "INV_Personengruppe",
            "0", // "INV_DMPKennzeichen",
            invoiceWithUser.gender === "female" ? 1 : 2, // "INV_Geschlecht",
            "", // "INV_Freigabe",
            "", // "INV_FreigabeBeginn",
            "", // "INV_FreigabeEnde",
            "", // "RGI_EinzelBisDatum",
            "", // "RGI_EinzelVonDatum",
            "", // "RGI_EinzelIKRechnungsteller",
            "", // "RGI_EinzelIKZahlungsempfaenger",
            "", // "RGI_EinzelInfoRechnung",
            "", // "RGI_EinzelRechNummer",
            "", // "ABR_Anzahl",
            "", // "ABR_Begruendung",
            "", // "ABR_Sachkosten",
            "", // "ABR_Sachkostenbezeichnung",
            "", // "ABR_DatumLeistung",
            "", // "ABR_GebNr",
            "", // "ABR_WertGebNr",
            "", // "GebIDIntern",
            "" // "DIA_ICDSchluessel"
        ],
        [
            invoiceWithUser.id, // "ReferenzID", // User ID
            "POS", // "Satzart", // KOP | POS
            "", // "IVK_IKEmpfaenger",
            "", // "IVK_IKSender",
            "", // "IVK_VerarbeitungsKZ",
            "", // "IBL_IKLeistungserbringer",
            "", // "Hauptabrechnungszeitraum"
            "", // "INF_VertragsKZ",
            "", // "INF_ArtAnspruch",
            "", // "INV_GebDatum",
            "", // "INV_Nummer",
            "", // "INV_IKKrankenKasse",
            "", // "INV_Name",
            "", // "INV_Vorname",
            "", // "INV_GueltigkeitKarte",
            "", // "INV_VersichertenArt",
            "", // "INV_Personengruppe",
            "", // "INV_DMPKennzeichen",
            "", // "INV_Geschlecht",
            "", // "INV_Freigabe",
            "", // "INV_FreigabeBeginn",
            "", // "INV_FreigabeEnde",
            "", // "RGI_EinzelBisDatum",
            "", // "RGI_EinzelVonDatum",
            "", // "RGI_EinzelIKRechnungsteller",
            "", // "RGI_EinzelIKZahlungsempfaenger",
            "", // "RGI_EinzelInfoRechnung",
            "", // "RGI_EinzelRechNummer",
            "1", // "ABR_Anzahl",
            "", // "ABR_Begruendung",
            "", // "ABR_Sachkosten",
            "", // "ABR_Sachkostenbezeichnung",
            invoiceWithUser.subscription_reason != null ? formatDate(new Date(invoiceWithUser.subscription_created_at)).replaceAll("-", "") : formatDate(new Date(invoiceWithUser.registration_date)).replaceAll("-", ""), // "ABR_DatumLeistung",
            "GWQMEDMO1", // "ABR_GebNr",
            "143,5", // "ABR_WertGebNr",
            "GWQMEDMO1", // "GebIDIntern",
            "" // "DIA_ICDSchluessel"
        ]
    ];
}

function createStandardEntriesFrom(invoiceWithUser: SubscriptionWithUser) {
    return [
        [
            invoiceWithUser.id,
            formatDate(invoiceWithUser.registration_date ? new Date(invoiceWithUser.registration_date) : undefined),
            formatDate(invoiceWithUser.billing_interaction ? new Date(invoiceWithUser.billing_interaction) : undefined),
            formatDate(invoiceWithUser.subscription_start_date ? new Date(invoiceWithUser.subscription_start_date) : undefined),
            formatDate(invoiceWithUser.subscription_end_date ? new Date(invoiceWithUser.subscription_end_date) : undefined),
            formatDate(invoiceWithUser.subscription_created_at ? new Date(invoiceWithUser.subscription_created_at) : undefined),
            invoiceWithUser.total_interactions,
            invoiceWithUser.email,
            formatDate(invoiceWithUser.birthdate ? new Date(invoiceWithUser.birthdate) : undefined),
            invoiceWithUser.insurance_number,
            invoiceWithUser.full_name,
            invoiceWithUser.company_name,
            invoiceWithUser.token,
            invoiceWithUser.token_alias,
            invoiceWithUser.company_token_batch_name,
            invoiceWithUser.company_token_batch_id,
            invoiceWithUser.subscription_reason
        ]
    ];
}

const headCells = [
    { id: "id", numeric: false, disablePadding: true, label: "User Id" },
    { id: "subscription_id", numeric: false, disablePadding: true, label: "Subscription Id" },
    { id: "email", numeric: true, disablePadding: false, label: "Email" },
    { id: "name", numeric: true, disablePadding: false, label: "Name" },
    { id: "insurance_number", numeric: true, disablePadding: false, label: "Insurance #" },
    { id: "company_id", numeric: true, disablePadding: false, label: "Company Id" },
    { id: "subscription_reason", numeric: false, disablePadding: false, label: "Subscription reason" },
    { id: "billed_at", numeric: false, disablePadding: false, label: "Billed at" },
    { id: "bill_paid_at", numeric: false, disablePadding: false, label: "Payed at" },
    { id: "token", numeric: true, disablePadding: false, label: "Token" },
    { id: "token_alias", numeric: true, disablePadding: false, label: "Token alias" },
    { id: "company_token_batch_name", numeric: true, disablePadding: false, label: "Company token batch name" },
    { id: "company_token_batch_id", numeric: true, disablePadding: false, label: "Company token batch id" }
];

function getCSV(data: SubscriptionWithUser[], format: "140A" | "standard") {
    const BOM = "\uFEFF";
    if (format === "140A") {
        const downloadData = data.flatMap((invoiceWithUser) => create140AEntriesFrom(invoiceWithUser));
        return [BOM, entryToCSVRow(fields_140a), ...downloadData.map((e) => entryToCSVRow(e))];
    } else if (format === "standard") {
        const downloadData = data.flatMap((invoiceWithUser) => createStandardEntriesFrom(invoiceWithUser));
        return [BOM, entryToCSVRow(fieldsStandard), ...downloadData.map((e) => entryToCSVRow(e))];
    } else {
        throw Error("Unknown format");
    }
}
